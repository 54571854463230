.profile-pic {
  /* height: 165px; */
  width: 250px;
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: start;
  position: relative;
  transition: all 0.3s ease;
}
.profile-pic input {
  display: none;
}
.profile-pic img {
  position: absolute;
  object-fit: cover;
  width: 165px;
  height: 165px;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, .35);
  border-radius: 100px;
  z-index: 0;
}
.profile-pic .-label {
  opacity: 0;
  cursor: pointer;
  height: 165px;
  width: 165px;
}
.profile-pic:hover .-label {
  opacity: 1;
  height: 165px;
  width: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .8);
  z-index: 10000;
  color: #fafafa;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}
.profile-pic span {
  display: inline-flex;
  padding: 0.2em;
  height: 2em;
}
 .body-profile {
  height: 100vh;
  background-color: #191815;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body-profile a:hover {
  text-decoration: none;
}

/* .card-data{
  background-color: green;
  display: flex;
}

.hi{
  background-color: red;
  height: 150px;
  width: 200px;
} */

.vertical-line {
  display: block;
  width: 2px;
  background-color: rgba(255, 255, 255, .35);
  margin-right: 10px;
}

@media (max-width: 767px) {
  .vertical-line {
    display: none;
  }
}

.address{
  padding-top: 30px;
}