@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap");

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}


.desktop66{
    display: block;
}
.navMenu{
  position: fixed;
  z-index: 99;
  width: 100%;
  background: #242526;
}
.navMenu .wrapper{
  position: relative;
  max-width: 100%;
  padding: 0px 70px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  
  /* justify-content: space-between; */
}
.wrapper .logo img a{
  color: #f2f2f2;
  font-size: 50px;
  font-weight: 600;
  text-decoration: none;
  margin-right: 50px;
  margin-top: 10px;
}
.wrapper .nav-links{
  display: inline-flex;
  width: 100%;
  
}
.nav-links li {
  list-style: none;
  margin: 3px 2px 0px 0px; /* Corrigido '3x' para '3px' */
  padding-left: 5px;
}

.nav-links li a {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 14px;
  font-weight: 100;
  padding: 15px 8px 15px 8px;
  border-radius: 5px;
  transition: all 0.3s ease;
  
}

.nav-links li img {
  width: 26px;
  border: 3px solid #d3940b;
  float: left;
  padding: 1px;
  margin-top: 50px;
  margin-right: 15px;
  margin-left: 5px;
  border-radius: 10px;
  transition: all 0.3s ease; /* Adicionado efeito de transição para a imagem */
  transform: scale(1.5);
  border-image: linear-gradient(to right, #0e0d0d, transparent, #d3940b); /* Adicionado efeito degradê na borda da imagem quando o mouse passa sobre o link <a> */
  border-image-slice: 1; /* Certifica-se de que o degradê cubra toda a borda */
}
.nav-links li img:hover {
  border-image: linear-gradient(to right, d3940b, transparent, #0e0d0d); /* Adicionado efeito degradê na borda da imagem quando o mouse passa sobre o link <a> */
  border-image-slice: 1; /* Certifica-se de que o degradê cubra toda a borda */
}

.nav-links li a:hover {
  background: #3A3B3C;
}

.nav-links li :hover img {
  transition: border 0.3s ease; /* Adicionado efeito de transição para a borda */
  border-image: linear-gradient(to right, d3940b, transparent, #0e0d0d); /* Adicionado efeito degradê na borda da imagem quando o mouse passa sobre o link <a> */
  border-image-slice: 1; /* Certifica-se de que o degradê cubra toda a borda */
}

.nav-links .mobile-item{
  display: none;
}
.nav-links .drop-menu{
  position: absolute;
  background: #242526;
  max-width: 300px;
  line-height: 15px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
  border-bottom-right-radius: 10px;
  padding: 10px 5px 10px 5px;
  text-align: justify;
  
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
  
}
.drop-menu li a{
  width: 100%;
  display: block;
  padding-left: 35px;
  padding-bottom: 1px;
  font-weight: 400;
  border-radius: 5px;
  line-height: 15px;
  font-size: 18px;
  
}
/* Ícone de seta */
.desktop-item i {
    font-size: 16px;
    margin-left: 2px;
  }
.drop-menu li a p{
    margin-top: 0px;
    padding-top: 10px;
    font-size: 11px;
    color: #dd9a08;
    position: relative;
}




.desktop-item.focused i {
    /* Estilos para o ícone quando o elemento está com foco */
    transform: rotate(180deg);
    color:#dd9a08;
  }

 /* Ícone de seta para baixo */
 .desktop-item i {
    transform: rotate(0deg); /* Ícone para baixo por padrão */
  }


.wrapper .btn{
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn{
  position: absolute;
  right: 30px;
  top: 10px;
}

divider {
    display: block;
    width: 80;
    height: 1px; /* A altura desejada para a linha divisória */
    background-color: #444444; /* Cor da linha divisória */
    margin: 10px 0; /* Espaçamento acima e abaixo da linha divisória */
  }
  


/* Estilos para a seção à direita do cabeçalho */
.nav-links-direito {
    right: -60px;
    position: absolute;
    display: flex;
    align-items: baseline;
    width: 450px;
  }

  .nav-links-direito-logado {
    left: 76px;
    position: relative;
    display: flex;
    align-items: center;
    width: 260px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
  }
  


  .perfil li a{
    color:orange;
  }
  
  .nav-links-direito a {
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
    font-weight: 500;
    font-size: 16px;
    transition: color 0.3s ease; /* Adicione uma transição de cor para o hover */
  }
  
  .nav-links-direito a:hover {
    color: #FFA500; /* Altere a cor no hover para laranja (#FFA500) */
  }
  
  .icone-mundo {
    color: #fff;
    font-size: 20px;
    margin-right: 20px;
    transition: color 0.3s ease; /* Adicione uma transição de cor para o hover */
    cursor: pointer;
  }
  
  .icone-mundo:hover {
    color: #FFA500; /* Altere a cor no hover para laranja (#FFA500) */
  }
  
  /* Botão "Cadastrar-se" */
  .btn-cadastrar {
    background-color: #ffc107; /* Cor de fundo laranja (#FFA500) */
    color: #0f0f0f;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    transition: background 0.5s ease, color 0.3s ease; /* Adicione transições de cor para o hover */
  }
  
  .btn-cadastrar:hover {
    background-color: #353534; /* Altere a cor de fundo no hover para dourado (#FFD700) */
    color: #050505;
  }
  
  /* Estilos para o link "Login" no hover */
  .nav-links-direito a.login:hover {
    color: #FFA500; /* Altere a cor no hover para laranja (#FFA500) */
  }

  .menu-lateral {
    width: 300px;
    height: 100%;
    background-color: #333;
    position: fixed;
    right: -300px; /* Inicialmente oculto à direita */
    transition: right 0.3s;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin-top: 60px;
  }


  .balance-menu {
    font-size: 14px; /* Tamanho de fonte desejado */
    color: #fff; /* Cor do texto desejada */
    margin: 10px 0; /* Espaçamento superior e inferior */
    line-height: 5px;
    padding: 20px 5px 15px 15px;
    background-color: #242526;
    margin-left: 15%;
    margin-right: 15%;
    border-radius: 10px;
    height: 85px;
    
  }

  .balance-menu .usd {
    padding-top: 25px;
    font-size: 22px;
    color:orange;

  }

  /* Use media queries para estilos específicos em telas menores */
@media (max-width: 1110px) {
  .navMenu .wrapper {
    padding: 0 2%; /* Reduza o preenchimento em telas menores */
  }

  .wrapper .logo img a {
    font-size: 1.5rem; /* Reduza o tamanho da fonte em telas menores */
  }

  .nav-links a {
    font-size: 13px;
    padding: 2px 2px 2px 2px ;
  }

  .nav-links .mobile-item {
    display: inline; /* Mostre os links em um menu móvel */
  }
  .menu-list {
    max-height: 200px; /* Define a altura máxima */
    overflow-y: auto; 
    font-size: 12px;
    
  }


}
  .menu-lateral.active {
    right: 0;
    top:0;
  }
  
  .menu-toggle {
    background-color: #333;
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 5px;
    left: 10px;
    padding: 10px;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  
  .menu-toggle:hover {
    background-color: #555;
  }
  
  .header-perfil {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #333;
    color: #fff;
    line-height: 16px;
    font-size: 14px;
    border-bottom: 1px solid #3A3B3C;
  }
  
  .avatar img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
  
  .user-info h2 {
    font-size: 16px;
    margin: 0;
  }
  
  .user-info p {
    font-size: 12px;
    margin: 0;
  }
  
  .user-info p:last-child {
    font-style: normal;
  }
  
  .menu-toggle {
    background-color: #333;
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 5px;
    left: 10px;
    padding: 10px;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  
  .menu-toggle:hover {
    background-color: #555;
  }
  
  .menu-list {
    list-style: none;
    padding: 0;
    margin: 10px 20px;
    
    
  }
  
  .menu-link {
    display: block;
    height: 45px;
    padding: 10px;
    padding-left: 10px;
    line-height: 45px;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.2s;
    border-radius: 10px;
    font-size: 16px;
  }
  .menu-link i{
    padding-right: 5px;
    font-size: 18px;
  }
  
  .menu-link:hover {
    transform: translateX(10px);
  }
  
  .footerMenu {
    position: absolute;
    bottom: 55px; /* Alinha no fundo do menu lateral */
    left: 0;
    right: 0;
    text-align: center; /* Centraliza o texto horizontalmente */
    font-size: 12px; /* Define o tamanho da fonte para 12px */
    font-weight: 100;
    background-color: #333; /* Cor de fundo desejada */
    color: #fff; /* Cor do texto desejada */
    padding: 5px 0; /* Espaçamento interno desejado */
  }


  .BtnMenuWhats {
    top: 55px; /* Alinha no fundo do menu lateral */
    left: 0;
    right: 0;
    text-align: center; /* Centraliza o texto horizontalmente */
    font-size: 12px; /* Define o tamanho da fonte para 12px */
    background-color: #333; /* Cor de fundo desejada */
    color: #fff; /* Cor do texto desejada */
    padding: 5px 0; /* Espaçamento interno desejado */
  }

  .btn-atendimentoMenu {
    background-color: #c7ab0c; /* Cor de fundo desejada */
    color: #fff; /* Cor do texto desejada */
    border: none;
    padding: 10px 20px; /* Espaçamento interno desejado */
    border-radius: 5px; /* Borda arredondada */
    cursor: pointer;
    transition: background-color 0.3s; /* Efeito de transição na mudança de cor de fundo */
  }
  
  .btn-atendimentoMenu:hover {
    background-color: #a08909; /* Nova cor de fundo ao passar o mouse */
    color:#d9d9d9;
  }
  
  .btn-logout {
    background-color: #343a40; /* Cor de fundo desejada */
    color: #fff; /* Cor do texto desejada */
    border: none;
    padding: 10px 20px; /* Espaçamento interno desejado */
    border-radius: 5px; /* Borda arredondada */
    cursor: pointer;
    transition: background-color 0.3s; /* Efeito de transição na mudança de cor de fundo */
  }
  
  .btn-logout:hover {
    background-color: #c9302c; /* Nova cor de fundo ao passar o mouse */
    color:#d9d9d9;
  }
  
  
.modal-languagem {
    border-radius: 10px;
  }
  
  /* Estilo do modal dark */
.modal-dark .modal-content {
    background-color: #343a40;
    color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    

}

.modal-dark .modal-header {
    border-bottom: 1px solid #454d55;
    background-color: #343a40;
    color: #ffffff;
}

.modal-dark .modal-title {
    font-weight: bold;
}

.modal-dark .modal-body {
    background-color: #343a40;
    color: #ffffff;
    border-radius: 10px;
}

.modal-dark .modal-footer {
    border-top: 1px solid #454d55;
    background-color: #343a40;
}

.modal-dark .close {
    color: #ffffff;
}

.modal-dark .language-list li {
    cursor: pointer;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 10px;
}

.modal-dark .language-list li:hover {
    background-color: #454d55;
}

.language-list {
    display: flex;
    flex-wrap: wrap; /* Permite que os elementos se ajustem automaticamente à largura máxima da linha */
    list-style: none; 
    padding: 0;
    justify-content: space-between; /* Distribui os elementos igualmente */
  }
  
  .language-list li {
    width: calc(33.33% - 10px); /* 33.33% largura por coluna (menos a margem direita) */
    margin-right: 10px;
    margin-bottom: 10px; /* Adiciona margem inferior para separar as linhas */
  }
  
  /* Adicione estilos adicionais conforme necessário */
  
  
  /* MobileMenu.css */

.mobile-menu-x9 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  position: relative;
  
}

.menu-toggle-x9 {
  font-size: 28px;
  cursor: pointer;
  padding-left: 10px;
  margin-top: -10px;

}

.nav-links_x9 {
  list-style: none;
  padding: 10ox;
  margin: 10px;
  display: none;
}

.nav-links_x9.open {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #242526; /* Cor de fundo */
  width: 200px;
}

.nav-links_x9.open a {
  text-decoration: none;
  color: #fff; /* Cor do texto */
  font-size: 14px;
  padding: 5px;
  margin: 15px;
  
}

.nav-links_x9 li:hover {
  background-color: #555;
  width: 200px;
}
