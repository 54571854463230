.loader-container {
    background-color: #1a18186e;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .loader {
    color: #ffffff;
    font-size: "22px";
  }