.app-icon {
	margin-left: 3%;
}
.tex-poup-check{
	font-size: 12px;
	color: #AEAED5 !important;
	height:20%;
}
.qrcode{
	display: flex;
	justify-content: space-around;

}
.font-cancel{
	color:rgb(16, 216, 118);
}
.tex-button{
	font-size: 12px;
	color: #fff; 
}
.tex-commun{
	font-size: 14px;
	color: #fff; 
}
.button-cancel{
	width: 100px;
    height: 30px;
	color: #fff; 
	margin-left: 3%;
	font-size: 12px;

	background-color:#bf0202;
	border-color:#bf0202 ;
}
.title-qrcode{
	font-size: 20px;
	color: #f0f0f8 !important;
	height:40%;
	padding-bottom: 5%;
	display: flex;
	justify-content: space-around;
	
}

.input-code{
	-webkit-appearance: none;
  margin: 0;	
	text-align: center;
	border-radius: 4px;
    border: 1px solid transparent;
    padding:5px 50px;
    font-size: 16px;
    font-weight: 100%;
    color: #a1a0a7;
    transition: all 0.3s ease-in-out;
    background: #000;
	display: flex;

}
.confirm-body{
	background:#131722;
}
.titleEmail{
	margin-top: 3%;
	color: #a1a0a7;

}
.rodape{
	margin-top: 3%;
	margin-bottom: 0;
	color: #a1a0a7;
}
.popup-overlay {
	height: 100%;
	width: 600px;
	margin: 0 auto;
	padding: 20px;
	background:#131722;
}
[data-popup='tooltip'].popup-overlay {
background: transparent;
}
/* Alerta Erro
----------------------------------------------- */
/* Geral */

.error {
	margin-top: 3%;
	height: 10%;
	width: 350px;
}
