
.slider-background {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .hero-section {
    position: relative;
    height: auto;
    padding: 173px 0 120px 0;
    border-bottom: 3px solid orange;
    box-shadow: 0px 5px 15px rgba(207, 152, 2, 0.2);
  }


.slider-image-container {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
  }
  