.card-coins {
  border: 0px solid #363C4E;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
  background: #131722;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card-coins:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 13px 0 rgba(82, 63, 105, 0.2);
}

/* card-select */
.card-select {
  padding: 15px 20px;
    /* background: #131722; */
    border-radius: 5px;
    border: #aa820a 1px solid;
}
.card-not-select {
  padding: 15px 20px;
    /* background: #131722; */
    border-radius: 5px;

}
input[type="text"]:disabled {
  background: #e5e6e7;
}

.content-body{
  padding: 70px 0px 0px;
}


.btn-buy {
  color: #fff;
  background-color: #0ca520;
  border-color: #069913;
  width: 100%;
}

.btn-buy:hover {
  color: #fff;
  background-color: #aa820a;
  border-color: #aa820a;
}

.btn-buy:focus, .btn-buy.focus {
  color: #fff;
  background-color: #0db463;
  border-color: #0ca95c;
  box-shadow: 0 0 0 0rem rgba(52, 222, 139, 0.5);
}



.exchange-market-list {
  display: flex; /* Torna os elementos filhos em linha */
  flex-direction: row; /* Alinha-os na horizontal (padrão) */
  align-items: center;
  gap: 10px;
  padding: 2px;
}



.market-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 55px;
  background-color: #292828;
  border: 1px solid #2c2a2a;
  border-radius: 3px;
  padding: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.1s;
  cursor: pointer;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-around;
  align-items: center;
}

.selected {
  background-color: #333232;
  }

.market-item:hover {
  background-color: #3a3737;
  transform: scale(1.02);
}

.market-item img {
  width: 20px;
  height: 20px;
  margin-right: 0px;
  border-radius: 50%;
  padding-left: 1px;
}

.market-info {
  flex-grow: 1;
  
}

.market-name {
  font-weight: bold;
  font-size: 12px;
  float: left;
  margin-top: 17px;
  
  
}

.market-name small{
  font-size: 10px;
  color: #a5a5a3;
}

.market-value {
  color: rgb(201, 201, 201);
  font-size: 14px;
  margin-top: 5px;
  padding: 2px;
  float: right;
  text-align: right;
}

.market-change {
  float: right;
  font-size: 10px;
}

.market-balance {
  font-size: 10px;
  position: relative;
  margin-top: 0px;
  padding: 0px;
}

.favorite-icon {
  font-size: 18px;
  color: #585856;
  padding-right: 5px;
}

.favorite-icon-selected {
  font-size: 18px;
  color: #fcd800;
  padding-right: 5px;
}


.tab-button {
  background-color: #1a73e8;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.tab-button:hover {
  background-color: #0e1122;
  transform: scale(1.05);
}


@keyframes gradient-highlight-danger {
  0% {
    background: linear-gradient(to right, #1d1d1d 0%, #572a2a 100%);
  }
  100% {
    background: linear-gradient(to right, transparent 0%, transparent 100%);
  }
}

@keyframes gradient-highlight-success {
  0% {
    background: linear-gradient(to right, #024204 0%, #0c9b30 100%);
  }
  100% {
    background: linear-gradient(to right, transparent 0%, transparent 100%);
  }
}

/* Aplique as animações às linhas */
tr.gradient-highlighted-danger {
  animation: gradient-highlight-danger 3.5s;
}

tr.gradient-highlighted-success {
  animation: gradient-highlight-success 3.7s;
}
