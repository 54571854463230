.balance-list {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.balance-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-icon {
  gap: 8px;
  align-items: center;
}

.content-icon img {
  width: 32px;
  height: auto;

}

/* @media screen and (max-width: 500px) {
  .balance-row {
    flex-direction: column;
    gap: 16px;
  }
  .content-prices {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }
  
  .content-icon img{
    width: 24px;
    height: auto;
  }
} */

.coin {
  font-weight: bold;
  color: #ccc !important;
  margin:0;
}

.balance {
  color: #cfcfcf;
  font-weight: bold;
  font-size: 16px;
}

.balance-card:hover {
  background: #293042 !important;
}
.item-separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  border: none;
}

.balance-separator {
  width: 100%;
  height: 1px;
  background-color: #353a4e;
  margin-top: 10px;
  margin-bottom: 10px;
}
