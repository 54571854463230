.button {
    margin: 20px 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .button.generate-pdf {
    margin-right: 10px;
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
}

.button.sign {
    width: 100px;
    background-color: #34c759;
    color: #fff;
    font-weight: bold;
}

.dialog{
    background-color: rgba(51, 51, 51, 0.5);
}

.dialog-content{
    padding: 1.25rem;
    background-color: #141622;
}

.sigCanvas{
    background-color: black;
    border-radius: 4px;
}